import React from "react"
import Layout from "../components/layout"
import { useIntl, FormattedMessage } from "gatsby-plugin-intl"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import Img from "gatsby-image"

const Recenzije = ({ data }) => {
  const intl = useIntl()
  const slike = data.allFile.edges

  return (
    <Layout>
      <SEO lang={intl.locale} title={intl.formatMessage({ id: "recenzije" })} />
      <div className="section blue-background lokacija">
        <div className="content">
          <div className="card">
            <h1>{intl.formatMessage({ id: "recenzije" })}</h1>
            <div className="recenzije-items">
              {slike.map((slika, i) => (
                <a key={i} href={slika.node.childImageSharp.fluid.src}>
                  <Img
                    key={slika.node.childImageSharp.id}
                    fluid={{
                      ...slika.node.childImageSharp.fluid,
                      sizes: "(min-width: 768px) 400px, 100vw",
                    }}
                    alt="review award image"
                  />
                </a>
              ))}

            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Recenzije

export const query = graphql`
  query {
    allFile(
      filter: { relativeDirectory: { regex: "/recenzije/" } }
      sort: { fields: name, order: ASC }
    ) {
      edges {
        node {
          childImageSharp {
            fluid(sizes: "(min-width: 10px) 500px, 500px", maxWidth: 500) {
              ...GatsbyImageSharpFluid
            }
          }
          id
          relativeDirectory
        }
      }
    }
  }
`
